/*
Palette
#ccdbdc (gainsboro)
#9ad1d4 (pale robin egg blue)
#80ced7 (middle blue)
#007ea7 (cerulean)
#003249 (prussian blue)
*/

.App {
  text-align: center;
  background-image: url('/pic/3px-tile.png');
  background-color: #003249;
  font-size: calc(10px + 2vmin);
  color: #ccdbdc;
  min-height: 100vh;
  overflow: hidden;
  -webkit-transform: translateZ(0);
}

.App-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.show-results .App-header {
  animation: reveal-results-header 0.4s ease-in-out forwards;
}

.search {
  z-index: 1000;
}

#search-field {
  width: 75%;
  font-size: 20pt;
  border: 1px solid #003249;
  padding: 0.2em;
  border-radius: 0.2em;
  color: #003249;
  box-shadow: inset 0px 0px 3px #003249;
  font-weight: 300;
}

#search-button {
  margin: 0.4em;
  padding: 0.1em 1em;
  background-color: #ccdbdc;
  font-size: 20pt;
  border: 1px solid #80ced7;
  border-radius: 0.2em;
  color: #003249;
  box-shadow: 0px 0px 4px #007ea7;
}
#search-button span {
  color: #007ea7;
  font-family: "Helvetica Neue";
  font-weight: 200;
  user-select: none;
}
#search-button.hidden {
  opacity: 0;
}
#search-button:focus {
  outline: none;
  border:none;
}
#search-button:active {
  background-color: #003249;
  color: #ccdbdc;
}

.show-results .search {
  animation: reveal-results-search 0.4s ease-in-out forwards;
}

.show-results .logo {
  animation: reveal-results-logo 0.3s ease-in-out forwards;
}
.logo {
  font-family: "Helvetica Neue";
  font-weight: 100;
  font-size: 60pt;
  line-height: 0.8em;
  background: radial-gradient(rgba(0,126,167,0.4) 0%, transparent 70%);
  width: 80%;
  height: 3em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 450px;
  z-index: 1;
}
.logo span {
  display: block;
}
.logo .smaller {
  font-size: 48pt;
  margin-top: -0.1em;
}

@keyframes reveal-results-header {
  from {
    min-height: 100vh;
  }
  to {
    min-height: 0vh;
  }
}
@keyframes reveal-results-logo {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}

@keyframes reveal-results-search {
  from {
    margin-top: 0px;
  }
  to {
    margin-top: -110px;
  }
}