.results {
  text-align: left;
  padding: 1.2em;
  height: auto;
  overflow-y: auto;
}

.results ul {
  columns: 3 auto;
  list-style-type: none;
  margin: 0;
  padding: 0;
}